import React from 'react';
import CommonPortfilo from './CommonPortfilo';

const PortfiloTwo = () => {
  return (
    <div>
      <CommonPortfilo condition={false} />
    </div>
  );
};

export default PortfiloTwo;
