import { useEffect, useState } from 'react';
import { nanoid } from 'nanoid';
// for work_images
import arteDigitalSS from '../assets/images/work_images/artedigital.png';
import ateneoSS from '../assets/images/work_images/ateneo-universitario.png';
import bay2bayoffices from '../assets/images/work_images/bay2bayoffices.png';
import cscGif from '../assets/images/work_images/csc-solutions.gif';
import downtownDentalSS from '../assets/images/work_images/downtown-dental-tj.png';
import greciaSpaSS from '../assets/images/work_images/greciaspa.png';
import merakimxSS from '../assets/images/work_images/merakimx.png';
import notaria5SS from '../assets/images/work_images/notaria5.png';
import oryxPluginSS from '../assets/images/work_images/oryx-plugin.png';
import oryxSS from '../assets/images/work_images/oryx.png';
import otorrinoSS from '../assets/images/work_images/otorrino-tijuana.png';
import paleteriaTropical from '../assets/images/work_images/paleteria-tropical.png';
import paulaFloresArtSS from '../assets/images/work_images/paulafloresart.png';
import sastreriaHocalisSS from '../assets/images/work_images/sastreriahocalis.png';
import pluginVln from '../assets/images/work_images/plugin-vln.png';
import rasaEstructurasSS from '../assets/images/work_images/rasa-estructura.png';
import roseExperienceSS from '../assets/images/work_images/rosexperience.png';
import shopaSS from '../assets/images/work_images/shopa-dashboard.gif';
import vetlookSS from '../assets/images/work_images/vetlook.png';
import vlnSS from '../assets/images/work_images/vinicola-la-nuestra.png';
import z2b2chat2 from '../assets/images/work_images/z2b2-chat-platform-2.png';

//  icon use as img here
import eCommerceIcon from '../assets/images/icons/e-commerce-2.svg';
import landingPageIcon from '../assets/images/icons/landingpage.svg';
import wordpressIcon from '../assets/images/icons/wordpress.svg';
import integrationServices from '../assets/images/icons/integration.svg';
import apiDevelopment from '../assets/images/icons/api-icon.png';
import customSoftware from '../assets/images/icons/custom-software.png';
// contact image
import { CgNotes } from 'react-icons/cg';
import { FaRegUser, FaAward } from 'react-icons/fa';
import { FiCodesandbox, FiCode } from 'react-icons/fi';
import { RiContactsBookLine } from 'react-icons/ri';
import { Link, useLocation } from 'react-router-dom';

import { MdOutlineBusinessCenter, MdOutlineSchool } from 'react-icons/md';

const AllData = () => {
  const [check, setCheck] = useState(false);
  const [local, setLocal] = useState(localStorage.getItem('theme'));
  const [singleData, setSingleData] = useState({});
  const [isOpen, setIsOpen] = useState(false);

  // dark and light theme controls
  useEffect(() => {
    const themeValue = localStorage?.getItem('theme');

    if (!themeValue) {
      setCheck(false);
      localStorage.setItem('theme', 'light');
    } else {
      themeValue === 'dark' && setCheck(true);
      themeValue === 'light' && setCheck(false);
    }

    localStorage?.getItem('theme') === 'dark'
      ? document.documentElement.classList.add('dark')
      : document.documentElement.classList.remove('dark');
  }, []);

  // Create and light theme function
  const handleTheme = value => {
    if (value === 'light') {
      setCheck(false);
      localStorage.setItem('theme', 'light');
      setLocal('light');
    } else {
      setCheck(true);
      localStorage.setItem('theme', 'dark');
      setLocal('dark');
    }
    localStorage?.getItem('theme') === 'dark'
      ? document.documentElement.classList.add('dark')
      : document.documentElement.classList.remove('dark');
  };

  // fillter portfilo data
  const handleData = text => {

    if (text === 'All') {
      setData(workItems);
    } else {
      const findData = workItems.filter(item => item.tag === text);
      setData(findData);
    }
  };

  // find items for portfilo  modal open
  const handleModelData = id => {
    console.log({ id, workItems });
    const find = workItems.find(item => item?.id === id);
    console.log(find);
    setSingleData(find);
    setIsOpen(true);
  };

  // Active navlinks function
  function NavLink({ to, className, activeClassName, inactiveClassName, ...rest }) {
    let location = useLocation();
    let isActive = location.pathname === to;
    let allClassNames = className + (isActive ? `${activeClassName}` : `${inactiveClassName}`);
    return <Link className={allClassNames} to={to} {...rest} />;
  }

  // Elements for protfilo section
  const workItems = [
    {
      id: 1,
      tag: 'Software Development',
      title: 'Agents Management System',
      img: cscGif,
      imgSmall: cscGif,
      bg: '#FFF3FC',
      client: 'CSC Solutions',
      languages: 'ReactJS, NodeJS',
      link: null,
      linkText: 'Not available',
      description:
        "Client industry is a call center, they required a system to manage their agents, clients and campaigns, the requirement was 'easy' but the logic behing was very complex, due to every single campaign is different than others and each one can ask for a different agent profile with a very variety of combinations between skills, experience and functions. It also has some specific functions such as: typing test, voice test, video recording, internet speed test and automated agent recommendation for new campaigns. I am currently redesigning it to it's 2.0 version, I didn't worked alone, I did it with 2 partners.",
    },
    {
      id: 2,
      tag: 'Software Development',
      title: 'Admin Dashboard',
      img: shopaSS,
      imgSmall: shopaSS,
      bg: '#C7CEEA',
      client: 'Ishi Medical',
      languages: 'ReactJS, NodeJS',
      link: null,
      linkText: 'Not available',
      description:
        'Client industry is health, they have an ecommerce with shopify and wanted a mobile app to sell their products, as well as an admin dashboard to manage those sales, I build the whole admin dashboard front-end by my own, but I got help from my partners with the backend. They also builded the mobile app.',
    },
    {
      id: 3,
      tag: 'E-commerce',
      title: 'Best used office furniture in the bay area',
      img: bay2bayoffices,
      imgSmall: bay2bayoffices,
      bg: '#B5EAD7',
      client: 'Bay 2 Bay Office Solutions',
      languages: 'Wordpress, Woocommerce',
      link: 'https://bay2bayoffices.com',
      linkText: 'bay2bayoffices.com',
      description:
        "My first international client, they are located in San Jose, California. Previously they had a very poor website, and had troubles with the previous developer. Then when we got in touch, we did a match (maybe because the owner is from Mexico?). So we started working and until today they still happy with the website, and have a lot of sells through the website. You're welcome 😎 hahaha just kidding, thanks for letting me be part of it.",
    },
    {
      id: 4,
      tag: 'Landing Page',
      title: 'University Website',
      img: ateneoSS,
      imgSmall: ateneoSS,
      bg: '#E2F0CB',
      client: 'Ateneo Universitario',
      languages: 'Wordpress',
      link: 'https://ateneo-universitario.edu.mx',
      linkText: 'ateneo-universitario.edu.mx',
      description:
        'This was the first time I ever had a customer from Education industry, they previously had a very poor website and wanted to renew it. So I did, I redesigned the whole website in less than a month due to the inscriptions period was coming and they needed as soon as possible. Here is the results and I have to say, they were so happy with te results!!',
    },
    {
      id: 5,
      tag: 'Plugins',
      title: 'Custom plugin form',
      img: notaria5SS,
      imgSmall: notaria5SS,
      bg: '#FFDAC1',
      client: 'Notaria 5',
      languages: 'PHP, Javascript, jquery, Wordpress API',
      link: 'https://notaria5tijuana.com/',
      linkText: 'notaria5tijuana.com',
      description:
        "While I was employee at ArteDigital, they had this client who needed a custom plugin to show up some forms according to their paper forms that people need to fill depending the service they're looking for.",
    },
    {
      id: 6,
      tag: 'Plugins',
      title: 'Wines Page Plugin',
      img: pluginVln,
      imgSmall: pluginVln,
      bg: '#FFB7B2',
      client: 'Vinicola La Nuestra',
      languages: 'PHP, ReactJS, Wordpress API',
      link: 'https://vinicolalanuestra.com/',
      linkText: 'vinicolalanuestra.com',
      description:
        "This customer at the beginning wanted an ecommerce, but then they realize that they don't need it, instead, we changed it to a simple landing page BUT, they periodically changes the price, and description of their wines so to avoid them change the information directly with the wordpress page builder, I created a custom plugin with ReactJS so they can edit, create and delete wines just once without knowledge about any page builder.",
    },
    {
      id: 7,
      tag: 'Landing Page',
      title: 'Website for Spa',
      img: greciaSpaSS,
      imgSmall: greciaSpaSS,
      bg: '#FFF0F8',
      client: 'Grecia SPA',
      languages: 'Wordpress, Javascript, jQuery, CSS',
      link: 'https://greciaspa.com.mx/',
      linkText: 'greciaspa.com.mx',
      description:
        "This customer started as an enterpreneur some years ago and they finally decided to create their website, it is not visible but it was builded under an E-commerce site because they want to sell products in the future. It's a very basic website just to show up their services. Pretty good I guess.",
    },
    {
      id: 8,
      tag: 'Software Development',
      title: 'Challenge accepted',
      img: vetlookSS,
      imgSmall: vetlookSS,
      bg: '#FF9AA2',
      client: 'Vetlook',
      languages: 'Wordpress, ReactJS, NodeJs, Stripe',
      link: 'https://vetlook.com.mx/',
      linkText: 'vetlook.com.mx',
      description:
        "This was our first software development project (and the worst but wait for the reason), this customer contacted us because they had a mobile app that was already started by colombian developers, the problem was that we should not have accepted it before a deep research inside the code, because after we started working with it, we realize that there was MANY things wrong or even some screens was just 'dump' screens with no functionality and the customer thought those functions were already done, but not. So we basically re-write the 70% of the code, also I build their website and the admin dashboard where the owners can see all the users registered, their payments, and manage the internal 'animal wiki'. We spent a lot of time and resources, in fact we failed to another customers because we believed on this one. At the end of the business relationship the customer owners decided to move to another developer who promise them to finish the project faster than us... The project died, I guess. But at least their website looks great, isn't? 😅",
    },
    {
      id: 9,
      tag: 'Landing Page',
      title: 'My first project',
      img: otorrinoSS,
      imgSmall: otorrinoSS,
      bg: '#C7CEEA',
      client: 'Dra. Alejandra',
      languages: 'Wordpress, CSS, HTML',
      link: 'https://otorrinotijuana.com/',
      linkText: 'otorrinotijuana.com',
      description:
        "So exiting!! This was my very first professional project, I just had academic experience before this one, I kno I know it may look poor today but I did it in 2016. The interesting thing about it is that it's the best positioned project I ever done, if you simply go to google and search 'otorrino tijuana', this website will appear in the firsts places, of course the domain name helped but also the content. I think it's time to a redesign, what do you think?",
    },
    {
      id: 10,
      tag: 'Software Development',
      title: 'Real time chat platform',
      img: z2b2chat2,
      imgSmall: z2b2chat2,
      bg: '#B5EAD7',
      client: 'Z2B2',
      languages: 'React, Redux-Saga, Websockets, .NET',
      link: null,
      linkText: 'Not available',
      description:
        "While I was employee at ArteDigital, they had this client who was starting a new business to sell jewerly products, their project consists in 4 different dashboards (customer, admin, client and real time chat platform). I builed all those fron-end dashboards alone, connected to a .NET backend builded by my co-worker Luis, I haven't work with websockets before except academic small projects. This was specially challenging because was the very first time I worked with redux-saga, plus... the websockets was not the 'websockets' we know with nodejs, it was another library for .NET so I had troubles at the beginning, but at the end it works perfectly.",
    },
    {
      id: 11,
      tag: 'Landing Page',
      title: 'My own freelance project',
      img: merakimxSS,
      imgSmall: merakimxSS,
      bg: '#E2F0CB',
      client: 'Meraki Mx',
      languages: 'Wordpress, jQuery, CSS',
      link: 'https://merakimx.com',
      linkText: 'merakimx.com',
      description:
        "I am not sure when I did this, but since 2016 when I started my freelance project, I changed the name 2 or 3 times, anyway the concept was the same. The Greek word Meraki is used to describe something that puts a lot of effort, creativity or love into; when you put something of yourself into what you do, whatever it is. It is a word that represents the idea of an intersection between the emotions of passion and dedication to something. The simplest definition of Meraki comes to be: 'Put soul, creativity and love into what you do, leaving a piece of yourself always in your work.'",
    },
    {
      id: 12,
      tag: 'Landing Page',
      title: 'Main company website',
      img: arteDigitalSS,
      imgSmall: arteDigitalSS,
      bg: '#FFDAC1',
      client: 'ArteDigital',
      languages: 'Wordpress, jQuery, CSS',
      link: 'https://artedigital-mx.com',
      linkText: 'artedigital-mx.com',
      description:
        'While I was employee at ArteDigital, they decided to redesign their website to a newer version, which I did as part of my job (not as a freelance). It was easy due to all the information was already in the old website and I just had to move it to the new template. A piece of cake!',
    },
    {
      id: 13,
      tag: 'Plugins',
      title: 'Restaurant Menu Plugin',
      img: oryxPluginSS,
      imgSmall: oryxPluginSS,
      bg: '#FFB7B2',
      client: 'Oryx Restaurante',
      languages: 'PHP, Javascript, jquery, Wordpress API',
      link: 'https://oryxrestaurante.com/en/food/',
      linkText: 'oryxrestaurante.com',
      description:
        'While I was employee at ArteDigital, they had this client who needed a custom plugin to show up their menu of dishes and drinks. They wanted it to be easy updatable, so I created a plugin to show and categorize every single item in both languages (English/Spanish). As well as a single shortcode to render it in the front. How does it look??',
    },
    {
      id: 14,
      tag: 'Landing Page',
      title: 'Sweet Ice Cream',
      img: paleteriaTropical,
      imgSmall: paleteriaTropical,
      bg: '#FFF0F8',
      client: 'Paleteria Tropical',
      languages: 'Wordpress, jQuery, CSS',
      link: 'https://paleteriatropical.com/',
      linkText: 'paleteriatropical.com',
      description:
        "Another landing page, in this project we did a photoshoot with my friend Pamela, this was a challenging project not for its complexity but for its industry, I've never worked with icecream so I didn't know what kind of design, or if the customer would like it. At the end this is the result and well... not my best work but also not my worst. We are not perfects. 😅",
    },
    {
      id: 15,
      tag: 'Landing Page',
      title: 'Do you like wine?',
      img: vlnSS,
      imgSmall: vlnSS,
      bg: '#FF9AA2',
      client: 'Vinicola La Nuestra',
      languages: 'Wordpress, jQuery, CSS',
      link: 'https://vinicolalanuestra.com/',
      linkText: 'vinicolalanuestra.com',
      description:
        "Firstable this started as an e-commerce, but then the client requested to make it a regular landing page due to they don't sell too much through the page. So here is the result, this design was made between their designer Leslie and me. Very clear, formal and interesting. Have you ever taste their wine?",
    },
    {
      id: 16,
      tag: 'Landing Page',
      title: 'Dental tourism?',
      img: downtownDentalSS,
      imgSmall: downtownDentalSS,
      bg: '#C7CEEA',
      client: 'Downtown Dental Clinic',
      languages: 'Wordpress, jQuery, CSS',
      link: 'https://downtowndentaltj.com/',
      linkText: 'downtowndentaltj.com',
      description:
        "I did this project 4/5 years ago, I was surprised by the 'dental tourism' term, I didn't heard it before. Basically their main patients are people from USA because dental treatments are cheaper in Mexico than USA. So part of the experience, the downtown dental staff helps their patients to do some tours. Besides that, they do a really good job, I started my brackets treatment with them actually. Recommended!! Btw, we just starting a whole new redesign of their website.",
    },
    {
      id: 17,
      tag: 'Landing Page',
      title: 'Delicious!',
      img: oryxSS,
      imgSmall: oryxSS,
      bg: '#B5EAD7',
      client: 'Oryx Restaurante',
      languages: 'Wordpress, jQuery, CSS',
      link: 'https://oryxrestaurante.com/en/',
      linkText: 'oryxrestaurante.com',
      description:
        "While I was employee at ArteDigital, they had this client who needs to redesign their website from scratch. Not too much to say, thei choose a Wordpress template, gave me the information and images and I started to work. I haven't visit them because it's not my food type. But I've heard it's very delicious!! Should we go someday?",
    },
    {
      id: 18,
      tag: 'Landing Page',
      title: "Let's build a building",
      img: rasaEstructurasSS,
      imgSmall: rasaEstructurasSS,
      bg: '#E2F0CB',
      client: 'Rasa Estructuras',
      languages: 'Wordpress, jQuery, CSS',
      link: 'https://rasaestructuras.com/',
      linkText: 'rasaestructuras.com',
      description:
        "Rasa estructuras is a company here in Tijuana that build the skeleton of the buildings, basically they are encharged of the first step when build a building, it's very impressive and important because of their work depends the whole structure. In this project the customer wanted not only the website but also a whole new corporate image so my brother who is graphic designer, helped me with this. We did a really great job. 💪🏼",
    },
    {
      id: 19,
      tag: 'Landing Page',
      title: 'Customer from another continent 😱',
      img: paulaFloresArtSS,
      imgSmall: paulaFloresArtSS,
      bg: '#FFDAC1',
      client: 'Artist Paula Flores',
      languages: 'Wordpress, jQuery, CSS',
      link: 'https://paulafloresart.com/',
      linkText: 'paulafloresart.com',
      description:
        "I believe that this is the most challenging project I've ever done, it looks simplier but it was completely different from the 'standars', Paula is a great mexican artist who's living in Austria, awesome! She has very interesting view points, and we created her website together. I had to stop thinking as a regular developer and think more like an artist. Abstract, original and beauty. What is the famous art piece you know?",
    },
    {
      id: 20,
      tag: 'E-commerce',
      title: 'Flowers, Love and Beauty',
      img: roseExperienceSS,
      imgSmall: roseExperienceSS,
      bg: '#FFF0F8',
      client: 'Rose Experience',
      languages: 'Wordpress, Woocommerce',
      link: null,
      linkText: 'Not available',
      description:
        "This project was an E-commerce enterpreneur founded by 3 guys, they believe in the love and the romantisism, we actually did a photoshoot again with my friend Pamela, and also I brought them flowers. I had to say, the project was very interesting and with a lot of potential. Unfortunately Rose Experience had to close due to the COVID pandemic, and the website had to be removed. But well, still being one of the beauties projects I've ever done. I regret I don't have another screenshot.",
    },
    {
      id: 21,
      tag: 'Landing Page',
      title: 'Tailor Shop in Tijuana',
      img: sastreriaHocalisSS, // Replace with the actual image variable
      imgSmall: sastreriaHocalisSS, // Replace with the actual small image variable
      bg: '#FFD700', // Example background color, adjust as needed
      client: 'Sastreria Hocalis',
      languages: 'Wordpress',
      link: 'https://sastreriahocalis.com',
      linkText: 'Visit Website',
      description:
        "This project involved developing a modern and responsive website for Sastreria Hocalis, a renowned tailor shop in Tijuana. The website showcases their bespoke tailoring services, allowing customers to book appointments online and view a gallery of their finest work. Key features include: - A user-friendly interface designed with React and Typescript - Real-time data management and authentication using Firebase - An integrated booking system for scheduling appointments - A gallery section to display custom-tailored suits and garments - SEO optimization to enhance online visibility and attract more clients.",
    },
    {
      id: 22,
      tag: 'Software Development',
      title: 'Tailor Shop Order Management System',
      img: sastreriaHocalisSS, // Replace with the actual image variable
      imgSmall: sastreriaHocalisSS, // Replace with the actual small image variable
      bg: '#FFD700', // Example background color, adjust as needed
      client: 'Sastreria Hocalis',
      languages: 'React, Typescript, Firebase',
      link: null,
      linkText: 'Not available for public access',
      description:
        "This project involved developing a modern and responsive order management system for Sastreria Hocalis, a renowned tailor shop in Tijuana. The system streamlines the process of managing customer orders, tracking progress, and ensuring timely delivery. Key features include: - A user-friendly interface designed with React and Typescript - Real-time data management and authentication using Firebase - An integrated order tracking system for monitoring the status of tailoring projects - A customer portal for viewing order history and updates. And whatsapp integration to notify the customer when their order is ready.",
    },
  ];
  const [data, setData] = useState(workItems);

  // Menu items for Homepage
  const menuItemTwo = [
    {
      id: nanoid(),
      name: 'About',
      link: '/about',
      icon: <FaRegUser />,
    },
    {
      id: nanoid(),
      name: 'Resume',
      link: '/resume',
      icon: <CgNotes />,
    },
    {
      id: nanoid(),
      name: 'Stack',
      link: '/stack',
      icon: <FiCode />,
    },
    {
      id: nanoid(),
      name: 'Portfolio',
      link: '/works',
      icon: <FiCodesandbox />,
    },
    {
      id: nanoid(),
      name: 'Contact',
      link: '/contact',
      icon: <RiContactsBookLine />,
    },
  ];

  // experience items for about page in "What I do" section
  const myServices = [
    {
      id: nanoid(),
      icon: customSoftware,
      title: 'Custom Software Development',
      des: "I specialize in custom software development, leveraging a collaborative team approach. With a focus on innovation, we craft unique and scalable solutions tailored to your business needs. Let's transform your vision into reality through customized software that propels your business forward.",
      color: '#269FFF',
      bg: '#F3FAFF',
    },
    {
      id: nanoid(),
      icon: integrationServices,
      title: 'Integration Services',
      des: "I specialize in leading a collaborative team skilled in developing custom API integrations. Whether you're aiming for improved functionality or seamless connections with existing software, we're here to enhance your system's capabilities. Let's work together to streamline and elevate your software experience.",
      color: '#FF6080',
      bg: '#FFF4F4',
    },
    {
      id: nanoid(),
      icon: apiDevelopment,
      title: 'Custom API Development',
      des: "I lead a proficient team specializing in custom API development from scratch. Whether it's meeting unique requirements or enhancing system efficiency, we're dedicated to building APIs that align perfectly with your needs. Our expertise spans both microservices and monolithic architectures, with a strong focus on using NestJS for scalable and maintainable solutions. Let's collaborate to create seamless, tailored solutions for your software ecosystem.",
      color: '#FF6080',
      bg: '#FFF4F4',
    },
    {
      id: nanoid(),
      icon: eCommerceIcon,
      title: 'E-Commerce',
      des: "I lead a dynamic team specializing in custom e-commerce services using WordPress. From personalized online stores to seamless user experiences, we bring innovation to every click. Let's collaborate to enhance your online presence and drive success with tailored e-commerce solutions powered by WordPress.",
      color: '#D566FF',
      bg: '#FCF4FF',
    },
    {
      id: nanoid(),
      icon: landingPageIcon,
      title: 'Landing Page',
      des: "I spearhead a collaborative team dedicated to crafting custom websites and impactful landing pages. Our focus is on creating engaging online experiences tailored to elevate your brand. Let's collaborate to enhance your digital footprint and make your online presence stand out.",
      color: '#DDA10C',
      bg: '#FEFAF0',
    },
    {
      id: nanoid(),
      icon: wordpressIcon,
      title: 'Custom Wordpress Plugins',
      des: "I lead a dynamic team specializing in crafting custom plugins for WordPress and Shopify. Whether you're looking to enhance functionality or add unique features, we're here to amplify your online platform. Let's collaborate to tailor solutions that elevate your digital presence.",
      color: '#8774FF',
      bg: '#FCF4FF',
    },
  ];

  // Resume items for Resume page
  const resumeArray = [
    {
      type: 'Education',
      icon: MdOutlineSchool,
      items: [
        {
          id: nanoid(),
          date: '2022 - ',
          title: 'Psychology Degree',
          place: 'Universidad Humanitas',
          bg: '#FFF4F4',
        },
        {
          id: nanoid(),
          date: '2015 - 2021',
          title: 'Computer Science Degree',
          place: 'Tencológico Nacional de México; Campus Tijuana',
          bg: '#FFF4F4',
        },
        {
          id: nanoid(),
          date: '2010 - 2013',
          title: 'Business Administration',
          place: 'CETis 58',
          bg: '#FFF1FB',
        }
      ]

    },
    {
      type: 'Experience',
      icon: MdOutlineBusinessCenter,

      items: [
        {
          id: nanoid(),
          date: 'November 2022 - Current',
          title: 'Software Engineer III',
          place: 'JumpCloud',
          bg: '#EEF5FA',
        },
        {
          id: nanoid(),
          date: 'November 2022 - June 2023',
          title: 'Software Engineer III',
          place: 'Paypal',
          bg: '#EEF5FA',
        },
        {
          id: nanoid(),
          date: 'April 2022 - September 2022',
          title: 'Software Engineer II',
          place: 'Compass',
          bg: '#EEF5FA',
        },
        {
          id: nanoid(),
          date: '2020 - 2022',
          title: 'Software Engineer I',
          place: 'ArteDigital',
          bg: '#F2F4FF',
        },
        {
          id: nanoid(),
          date: '2016 - Present',
          title: 'Founder and Full-stack Software Engineer',
          place: 'Meraki One',
          bg: '#EEF5FA',
        }
      ]
    },
    {
      type: 'Certificates',
      icon: FaAward,
      items: [
        {
          id: nanoid(),
          date: '2022',
          title: 'Modern GraphQL',
          place: 'Udemy',
          bg: '#FCF4FF',
        },
        {
          id: nanoid(),
          date: '2021',
          title: 'React for the rest of us',
          place: 'Udemy',
          bg: '#FCF4FF',
        },
        {
          id: nanoid(),
          date: '2021',
          title: 'UI/UX Rules and Foundaments',
          place: 'Udemy',
          bg: '#FCF9F2',
        },
      ]
    },
  ];

  // Working Skills items for Resume page
  const lineArray = [
    {
      id: nanoid(),
      color: '#61DBFB',
      name: 'React JS',
      number: '75',
    },
    {
      id: nanoid(),
      color: '#764abc',
      name: 'Redux',
      number: '70',
    },
    {
      id: nanoid(),
      color: '#5185D4',
      name: 'Web Sockets',
      number: '60',
    },
    {
      id: nanoid(),
      color: '#21759b',
      name: 'WordPress',
      number: '75',
    },
    {
      id: nanoid(),
      color: '#FFCA28',
      name: 'Firebase',
      number: '50',
    },
    {
      id: nanoid(),
      color: '#A6A6A6',
      name: 'GIT',
      number: '70',
    },
    {
      id: nanoid(),
      color: '#563d7c',
      name: 'Tailwind CSS',
      number: '60',
    },
    {
      id: nanoid(),
      color: '#A6A6A6',
      name: 'NextJs',
      number: '10',
    },
    {
      id: nanoid(),
      color: '#007acc',
      name: 'Typescript',
      number: '70',
    },
    {
      id: nanoid(),
      color: '#CD6799',
      name: 'SCSS/SASS',
      number: '50',
    },
    {
      id: nanoid(),
      color: '#5433FF',
      name: 'Stripe',
      number: '70',
    },
    {
      id: nanoid(),
      color: '#A6A6A6',
      name: 'NestJS',
      number: '80',
    },
    {
      id: nanoid(),
      color: '#C63D14',
      name: 'Jest',
      number: '30',
    },
    {
      id: nanoid(),
      color: '#008AD7',
      name: 'Azure',
      number: '30',
    },
    {
      id: nanoid(),
      color: '#F0DB4F',
      name: 'Javascript',
      number: '85',
    },
    {
      id: nanoid(),
      color: '#47A248', // MongoDB
      name: 'MongoDB',
      number: '70', // Leave this to be filled
    },
    {
      id: nanoid(),
      color: '#336791', // PostgreSQL
      name: 'PostgreSQL',
      number: '60', // Leave this to be filled
    },
    {
      id: nanoid(),
      color: '#2D3748', // Prisma
      name: 'Prisma',
      number: '66', // Leave this to be filled
    },
    {
      id: nanoid(),
      color: '#52B0E7', // Sequelize
      name: 'Sequelize',
      number: '60', // Leave this to be filled
    },
    {
      id: nanoid(),
      color: '#000000', // Express
      name: 'Express',
      number: '60', // Leave this to be filled
    },
    {
      id: nanoid(),
      color: '#4FC08D', // Vue
      name: 'Vue',
      number: '60', // Leave this to be filled
    },
    {
      id: nanoid(),
      color: '#646CFF', // Vite
      name: 'Vite',
      number: '60', // Leave this to be filled
    },
    {
      id: nanoid(),
      color: '#0080FF', // Digital Ocean
      name: 'Digital Ocean',
      number: '50', // Leave this to be filled
    },
    {
      id: nanoid(),
      color: '#2496ED', // Docker
      name: 'Docker',
      number: '65', // Leave this to be filled
    },
    {
      id: nanoid(),
      color: '#F22F46', // Twilio
      name: 'Twilio',
      number: '65', // Leave this to be filled
    }
  ];

  const skillsArray = [
    'Always trying to be positive',
    'Fast learner',
    'Empathy',
    'Fresh Ideas',
    'Problem solving',
    'Friendly',
    'Funny',
    'Leadership',
    'Natural leader',
    'Passion for news techs',
    'Time Management',
    'Focused',
  ];

  function shuffle(arr) {
    return arr.sort(() => 0.5 - Math.random());
  }

  return {
    check,
    data,
    myServices,
    handleData,
    handleModelData,
    handleTheme,
    isOpen,
    lineArray,
    local,
    menuItemTwo,
    NavLink,
    resumeArray,
    setIsOpen,
    shuffle,
    singleData,
    skillsArray,
  };
};

export default AllData;
