import AOS from 'aos';
import 'aos/dist/aos.css';
import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ContextProvider from './Context/ContextProvider';
import About from './Pages/About/';
import Contact from './Pages/Contact/';
import Home from './Pages/Home/';
import Portfolio from './Pages/Portfilo/';
import Resume from './Pages/Resume/';
import Stack from './Pages/Stack/Stack';
import NotFound from './Share/NotFound';
import { WELCOME_FIRST_LOAD } from './utils';

function App() {
  const [start, setStart] = React.useState(false);
  const [skipped, setSkipped] = React.useState(false);

  useEffect(() => {
    AOS.init({ duration: 1200 });
    AOS.refresh();
  }, []);

  const initializeJarvis = () => {
    setStart(true);
    setTimeout(() => {
      // localStorage.setItem(WELCOME_FIRST_LOAD, true);
      window.dispatchEvent(new Event(WELCOME_FIRST_LOAD));
    }, 11700);
  };

  const skipJarvis = () => {
    setStart(true);
    setSkipped(true);
  };

  window.onbeforeunload = () => localStorage.removeItem(WELCOME_FIRST_LOAD);

  if (!start)
    return (
      <div className='flex flex-col items-center justify-center h-screen gap-4'>
        <button
          className='cursor-pointer bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'
          onClick={initializeJarvis}>
          Initialize System
        </button>
        <button
          className='cursor-pointer bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'
          onClick={skipJarvis}>
          Skip
        </button>
      </div>
    );

  return (
    <>
      <ContextProvider>
        <BrowserRouter>
          <Routes>
            <Route path='/' element={<Home skipped={skipped} />}>
              <Route index element={<About />} />
              <Route path='about' element={<About />} />
              <Route path='resume' element={<Resume />} />
              <Route path='stack' element={<Stack />} />
              <Route path='works' element={<Portfolio />} />
              <Route path='contact' element={<Contact />} />
            </Route>
            <Route path='*' element={<NotFound />} />
          </Routes>
        </BrowserRouter>
      </ContextProvider>
    </>
  );
}

export default App;
