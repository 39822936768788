import React from 'react';
import UseData from '../../Hooks/UseData';
import Footer from '../../Share/Footer';
import PageTitle from '../../Share/PageTitle';
import LineItem from './LineItem';

const Stack = () => {
  const { lineArray, skillsArray } = UseData();
  return (
    <>
      <PageTitle title='Stack / Skills'></PageTitle>
      {/* End pagetitle */}

      <section className='bg-white  lg:rounded-2xl dark:bg-[#111111]'>
        <div data-aos='fade'>
          <div className='container bg-color-810 dark:bg-[#0D0D0D] py-12 px-2 sm:px-5 md:px-10 lg:px-20'>
            <div className='grid grid-cols-1  md:grid-cols-2 gap-8'>
              <div className='col-span-1'>
                <h4 className='text-5xl dark:text-white font-medium mb-6'>Stack</h4>
                {/* Experience percent element */}
                {lineArray.map((item, i) => (
                  <LineItem item={item} key={i} />
                ))}
              </div>

              {/* knowledge section start */}

              <div className='col-span-1'>
                <h4 className='text-5xl dark:text-white font-medium mb-8'>Skills</h4>

                {/* Knowledges items */}

                <div className='flex gap-y-5 gap-x-2.5 flex-wrap'>
                  {skillsArray.map((s, idx) => (
                    <button key={idx} className='resume-btn'>
                      {s}
                    </button>
                  ))}
                </div>
              </div>
              {/* knowledge section end */}
            </div>
          </div>
          {/* End .container */}
          <Footer condition={false} bg={'#FFFF'} />
        </div>
      </section>
    </>
  );
};

export default Stack;
