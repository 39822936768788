import React from 'react';
import { Helmet } from 'react-helmet-async';

const PageTitle = () => {
  return (
    <Helmet>
      <title>Israel Santiago | Software Engineer</title>
      <meta
        name='description'
        content="Hello World! I'm glad you found me. This is my personal CV/portfolio. I am a Software Engineer focussed on Software Development"
      />
    </Helmet>
  );
};

export default PageTitle;
