import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import mySpace from './assets/images/my-space.jpg';
import favicon from './assets/images/favicon.ico';
import { getMyAge } from './utils';

const content = `Hello world! Let me introduce myself, I'm ${getMyAge()} and I am Software Engineer, feel free to wave through my portfolio and let me know your thoughts.`;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <HelmetProvider>
    <Helmet>
      <link rel='icon' type='image/ico' href={favicon} sizes='16x16' />
      <title>Israel Santiago | Software Engineer</title>
      <meta name='description' content={content} />
      <meta property='og:type' content='website' />
      <meta property='og:url' content='www.israelsantiago.com' />
      <meta property='og:title' content='Israel Santiago | Software Engineer | React x Nodejs' />
      <meta property='og:description' content={content} />
      <meta property='og:image' key='og:image' content={mySpace} />
    </Helmet>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </HelmetProvider>
);
