import React from 'react';
import './Loader.css';

const Loader = () => {
  return (
    <React.Fragment>
      <div id='jarvisMain'>
        <div id='jarvis-myCircle'>
          <div id='jarvis-mainCircle'>
            <div className='jarvis-circle'></div>
            <div className='jarvis-circle1'></div>
            <div id='jarvis-mainContent'>
              <h2 id='jarvis-mainText'>Hi!</h2>
              <ul className='jarvis-bars'>
                <li></li>
                <li></li>
                <li></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div id='jarvis-status'>
        <b>Status:</b> Loading Jarvis...
      </div>
    </React.Fragment>
  );
};

export default Loader;
