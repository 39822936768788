import '../../Share/Style.css';
import AboutCard from './AboutCard';
import Footer from '../../Share/Footer';
import HomeCard from '../../Share/HomeCard';
import PageTitle from '../../Share/PageTitle';
import React from 'react';
import UseData from '../../Hooks/UseData';

function yearsSince(year) {
  const currentYear = new Date().getFullYear();
  return currentYear - year;
}

const AboutTwo = () => {
  const { local, myServices } = UseData();

  const experienceYears = yearsSince(2017);

  return (
    <section>
      <PageTitle title='About'></PageTitle>
      {/* End pagetitle */}

      <div className=' lg:rounded-2xl bg-white dark:bg-[#111111]'>
        <div data-aos='fade'>
          <div className=' pt-12 md:py-12 px-2 sm:px-5 md:px-10 lg:px-14 '>
            {/* About page title */}
            <h2 className='after-effect after:left-52'>About Me</h2>
            <div className='lg:hidden'>
              {/* Sidebar personal information for mobile devices */}
              <HomeCard />
            </div>
            <div className='lg:grid grid-cols-12 md:gap-10 pt-4 md:pt-[30px] items-center hidden '>
              <div className='col-span-12 space-y-2.5'>
                <div className='lg:mr-16'>
                  <p className='text-justify text-gray-lite dark:text-color-910 leading-7'>
                    Hello! I'm a qualified software engineer with over {experienceYears} years of experience in Software Development, specializing in Javascript / Typescript. Starting as a self-taught programmer, I’m constantly seeking to enhance both my professional skills and personal growth. Fluent in English and Spanish, I'm working towards mastering five languages.
                  </p>
                  <p className='text-justify text-gray-lite leading-7 mt-2.5 dark:text-color-910'>
                    Reading is my passion, fueling my creativity, and I aspire to live in a different country such as Italy, London, Paris, Norway, Spain, Canada, or the USA.
                  </p>
                </div>
                <div></div>
              </div>
            </div>
          </div>
          {/* End about descriptions */}

          <div className='  pb-12 px-2 sm:px-5 md:px-10 lg:px-14 '>
            <h3 className='text-[35px] dark:text-white font-medium pb-5'>What I do!</h3>
            <div className='grid gap-8 grid-cols-1 md:grid-cols-2 xl:grid-cols-2 '>
              {/* Experience information  */}

              {myServices.map(item => (
                <AboutCard key={Math.random()} item={item} local={local} />
              ))}
            </div>
          </div>
          {/* service provide end */}

          {/* Common Footer call here */}
          <Footer bg={'#FFFF'} />
        </div>
      </div>
    </section>
  );
};

export default AboutTwo;
