import React, { useState } from 'react';
import Masonry from 'react-masonry-css';
import Modal from 'react-modal';
import UseData from '../../Hooks/UseData';
import Footer from '../../Share/Footer';
import { FiUser, FiCode, FiFilePlus, FiExternalLink } from 'react-icons/fi';
import { BsXCircle } from 'react-icons/bs';
import { useEffect } from 'react';
import PageTitle from '../../Share/PageTitle';

Modal.setAppElement('#root');

const CommonPortfilo = ({ condition }) => {
  const { local, handleData, data, handleModelData, isOpen, setIsOpen, singleData } = UseData();
  const [test, setTest] = useState('All');

  const handleSearch = text => {
    handleData(text);
    setTest(text);
  };

  useEffect(() => {
    setTest('All');
    handleData('All');
  }, []);
  const handleModle = id => handleModelData(id);

  const item = condition ? 3 : 2;

  const breakpointColumnsObj = {
    default: item,
    1100: 2,
    500: 1,
  };

  return (
    <>
      <PageTitle title='Portfolio'></PageTitle>
      {/* End pagetitle */}

      <section className='bg-white  lg:rounded-2xl dark:bg-[#111111]'>
        <div data-aos='fade'>
          <div className='container mb-8   px-4 sm:px-5 md:px-10 lg:px-[60px]'>
            <div className='py-12'>
              <h2 className='after-effect  after:left-48  lg:mt-0'>Portfolio</h2>

              <div className='lg:grid grid-cols-12 md:gap-10 pt-4 md:pt-[30px] items-center hidden '>
                <div className='col-span-12 space-y-2.5'>
                  <div className='lg:mr-16'>
                    <h2 className='text-orange-600 font-bold'>Please keep this in mind:</h2>
                    <p className='text-justify text-gray-lite  dark:text-color-910 leading-7'>
                      This is a brief list of some of the projects I have worked on. However, some of them are not updated, and I realized that they're out of date. Since I no longer work at the same company, I don't know if they modified them or discontinued the updates. Therefore, I can't assure that they will continue to look as good as they did.
                    </p>
                  </div>
                  <div></div>
                </div>
              </div>

              {/* Portfilo fillter tab start */}

              <ul
                className={`${item === 2 ? 'mt-[30px] ' : 'mt-[40px]'
                  } flex sm:flex-row w-full lg:justify-between md:justify-end flex-wrap font-medium`}>
                <li
                  className={`portfolio-tabs ${test === 'All' ? 'text-[#FA5252]' : 'fillter-btn '}`}
                  onClick={() => handleSearch('All')}>
                  All
                </li>
                <li
                  className={`portfolio-tabs ${test === 'Software Development' ? 'text-[#FA5252]' : 'fillter-btn ml-0'
                    }`}
                  onClick={() => handleSearch('Software Development')}>
                  Software Development
                </li>
                <li
                  className={`portfolio-tabs ${test === 'E-commerce' ? 'text-[#FA5252]' : 'fillter-btn'
                    }`}
                  onClick={() => handleSearch('E-commerce')}>
                  E-commerce
                </li>
                <li
                  className={`portfolio-tabs ${test === 'Landing Page' ? 'text-[#FA5252]' : 'fillter-btn'
                    }`}
                  onClick={() => handleSearch('Landing Page')}>
                  Landing Page
                </li>
                <li
                  className={`portfolio-tabs ${test === 'Plugins' ? 'text-[#FA5252]' : 'fillter-btn'
                    }  `}
                  onClick={() => handleSearch('Plugins')}>
                  Plugins
                </li>
              </ul>

              {/* Portfilo fillter tab start */}
            </div>

            {/* Portfolio items start */}

            <Masonry
              breakpointCols={breakpointColumnsObj}
              className='my-masonry-grid'
              columnClassName='my-masonry-grid_column'>
              {data.map((item, idx) => (
                <div
                  style={{
                    background: `${local === 'dark' ? 'transparent' : item?.bg}`,
                  }}
                  className='rounded-lg p-6 dark:border-[2px] border-[#212425]'
                  key={item.id}>
                  <h3 className='flex items-center text-[15px] sm:text-lg portfolio-badge'>
                    {idx + 1}
                  </h3>
                  <div className=' overflow-hidden rounded-lg'>
                    <img
                      onClick={() => handleModle(item.id)}
                      className='w-full    cursor-pointer transition duration-200 ease-in-out transform hover:scale-110 rounded-lg h-auto '
                      src={item?.imgSmall}
                      alt=''
                    />
                  </div>
                  <span className='pt-5 text-[14px] font-normal text-gray-lite block dark:text-[#A6A6A6]'>
                    {item?.tag}
                  </span>
                  <h2
                    onClick={() => handleModle(item.id)}
                    className='font-medium cursor-pointer text-xl duration-300 transition hover:text-[#FA5252] dark:hover:text-[#FA5252] dark:text-white mt-2'>
                    {item?.title}
                  </h2>
                </div>
              ))}
            </Masonry>

            {/* Portfolio items end */}

            {/* Portfolio modal start */}

            <Modal
              isOpen={isOpen}
              onRequestClose={() => setIsOpen(false)}
              className=' outline-none flex items-center  p-4 md:p-8  rounded-2xl my-8'>
              <div className=' w-full md:w-10/12 flex items-center   lg:w-[850px] bg-white dark:bg-[#323232] mx-auto rounded-xl p-4 md:p-8 absolute left-1/2 top-1/2 transform -translate-x-[50%] -translate-y-[50%] shadow-lg '>
                <div className=' overflow-y-scroll max-h-[80vh] no-scrollbar '>
                  {/* close button */}
                  <BsXCircle
                    onClick={() => setIsOpen(false)}
                    className='text-7xl cursor-pointer  absolute right-2 -top-12 md:-right-10 md:-top-6 z-50  text-white transition transform hover:rotate-45 duration-300 ease-in-out '
                  />
                  <h2 className='text-[#ef4060] dark:hover:text-[#FA5252] text-4xl text-center font-bold'>
                    {singleData?.tag} Project
                  </h2>
                  <div className='grid grid-cols-1 lg:grid-cols-2 my-6'>
                    <div className='space-y-2'>
                      <p className='dark:text-white flex items-center text-[15px]  sm:text-lg '>
                        <FiFilePlus className='sm:text-lg hidden sm:block mr-2  md:text-xl' />
                        Project :&nbsp;
                      </p>
                      <p className='dark:text-white flex items-center text-[15px]  sm:text-lg font-medium '>
                        {singleData?.title}
                      </p>
                      <p className='dark:text-white flex items-center text-[15px]  sm:text-lg '>
                        <FiCode className='text-lg mr-2 hidden sm:block ' />
                        Languages :&nbsp;
                      </p>
                      <p className='dark:text-white flex items-center text-[15px]  sm:text-lg font-medium '>
                        {singleData?.languages}
                      </p>
                    </div>
                    <div className='space-y-2'>
                      <p className='dark:text-white flex items-center mt-2 lg:mt-0 text-[15px]  sm:text-lg '>
                        <FiUser className='text-lg mr-2 hidden sm:block' />
                        Client :&nbsp;
                        <span className='font-medium '>{singleData?.client}</span>
                      </p>
                      <p className='dark:text-white flex items-center text-[15px] sm:text-lg '>
                        <FiExternalLink className='text-lg mr-2 hidden sm:block' />
                        Preview :&nbsp;
                        <span className='font-medium transition-all duration-300 ease-in-out hover:text-[#ef4060] '>
                          <a href={singleData?.link} target='_blank' rel='noopener noreferrer'>
                            {singleData?.linkText}
                          </a>
                        </span>
                      </p>
                    </div>
                  </div>
                  <p className='dark:text-white text-2line font-normal text-[15px] sm:text-sm text-justify'>
                    {singleData?.description}
                  </p>
                  <img
                    className='w-full md:h-[450px]  h-auto object-cover rounded-xl mt-6'
                    src={singleData?.img}
                    alt=''
                  />
                </div>
              </div>
            </Modal>

            {/* Portfolio modal end */}
          </div>
          {/* End Portfolio */}

          {/* Common Footer call here*/}
          <Footer condition={true} bg={'#F8FBFB'} />
        </div>
      </section>
    </>
  );
};

export default CommonPortfilo;
