import React from 'react';
import {
  FaCalendarAlt,
  FaFacebookF,
  FaLinkedinIn,
  FaMapMarkerAlt,
  FaMobileAlt,
  FaInstagram,
  FaGithub,
  FaGitlab,
  FaWhatsapp,
} from 'react-icons/fa';
import { SiReact } from 'react-icons/si';
import gmailIcon from '../assets/images/icons/gmail.svg';
import language from '../assets/images/icons/language.png';
import img from '../assets/images/israel-santiago-profile-image.png';
import downloadIcon from '../assets/images/download.png';
import { getMyAge, updatedCV } from '../utils/';

const HomeCard = () => {
  return (
    <div>
      <div className='w-full mb-6 lg:mb-0  mx-auto   relative bg-white text-center dark:bg-[#111111] px-6 rounded-[20px] mt-[180px] md:mt-[220px] lg:mt-0 '>
        <img
          src={img}
          className='w-[240px] absolute left-[50%] transform -translate-x-[50%] h-[240px] drop-shadow-xl mx-auto  rounded-[20px] -mt-[140px]'
          alt=''
        />
        {/* Social card */}
        <div className='pt-[100px] pb-8'>
          <h1 className='mt-6 mb-1 text-5xl font-semibold  dark:text-white'>Israel Santiago</h1>
          <h3 className='mb-4 text-[#7B7B7B] inline-block dark:bg-[#1D1D1D] px-5 py-1.5 rounded-lg dark:text-[#A6A6A6]  '>
            Software Engineer III
          </h3>

          <div className='flex justify-center space-x-3'>
            <a
              href='https://www.facebook.com/isantiago95'
              target='_blank'
              rel='noopener noreferrer'>
              <span className='socialbtn text-[#4267B2]'>
                <FaFacebookF />
              </span>
            </a>
            <a
              href='https://instagram.com/whatafeeling__'
              target='_blank'
              rel='noopener noreferrer'>
              <span className='socialbtn text-[#C13584]'>
                <FaInstagram />
              </span>
            </a>
            <a
              href='https://www.linkedin.com/in/isantiago95/'
              target='_blank'
              rel='noopener noreferrer'>
              <span className='socialbtn text-[#0077b5]'>
                <FaLinkedinIn />
              </span>
            </a>
            <a href='https://www.github.com/isantiago95/' target='_blank' rel='noopener noreferrer'>
              <span className='socialbtn text-[#A6A6A6]'>
                <FaGithub />
              </span>
            </a>
            <a href='https://gitlab.com/isantiago95' target='_blank' rel='noopener noreferrer'>
              <span className='socialbtn text-[#FC6D27]'>
                <FaGitlab />
              </span>
            </a>
          </div>

          <div className='p-7 rounded-2xl mt-7  bg-[#F3F6F6] dark:bg-[#1D1D1D]'>
            <div className='flex border-b border-[#E3E3E3] dark:border-[#3D3A3A] pb-2.5'>
              <span className='socialbtn bg-white dark:bg-black text-[#25a839] shadow-md'>
                <FaWhatsapp />
              </span>
              <div className='text-left ml-2.5'>
                <p className='text-xs text-[#44566C] dark:text-[#A6A6A6]'>Phone</p>
                <p className='dark:text-white'>
                  <a href='https://wa.me/+5216641764359' target='_blank' rel='noopener noreferrer'>
                    +52 1 664 176 4359
                  </a>
                </p>
              </div>
            </div>
            <div className='flex border-b border-[#E3E3E3] dark:border-[#3D3A3A] py-2.5'>
              <span className='socialbtn bg-white dark:bg-black text-[#6AB5B9] shadow-md'>
                <img src={gmailIcon} alt='gmail' style={{ width: '1em' }} />
              </span>
              <div className='text-left ml-2.5'>
                <p className='text-xs text-[#44566C] dark:text-[#A6A6A6]'>Email</p>
                <p className='dark:text-white'>
                  <a
                    href='mailto:me@israelsantiago.com'
                    target='_blank'
                    rel='noopener noreferrer'>
                    me@israelsantiago.com
                  </a>
                </p>
              </div>
            </div>

            <div className='flex border-b border-[#E3E3E3] dark:border-[#3D3A3A] py-2.5'>
              <span className='socialbtn bg-white dark:bg-black text-[#FD7590] shadow-md'>
                <FaMapMarkerAlt />
              </span>
              <div className='text-left ml-2.5'>
                <p className='text-xs text-[#44566C] dark:text-[#A6A6A6]'>Location</p>
                <p className='dark:text-white'>
                  <a
                    href='https://goo.gl/maps/s4F8nUSGyAVYkqjL9'
                    target='_blank'
                    rel='noopener noreferrer'>
                    Tijuana Baja California
                  </a>
                </p>
              </div>
            </div>
            <div className='flex border-b border-[#E3E3E3] dark:border-[#3D3A3A] py-2.5'>
              <span className='socialbtn bg-white dark:bg-black text-[#C17CEB] shadow-md'>
                <FaCalendarAlt />
              </span>
              <div className='text-left ml-2.5'>
                <p className='text-xs text-[#44566C] dark:text-[#A6A6A6]'>Age</p>
                <p className='dark:text-white'>{getMyAge()}</p>
              </div>
            </div>

            <div className='flex border-b border-[#E3E3E3] dark:border-[#3D3A3A] py-2.5'>
              <span className='socialbtn bg-white dark:bg-black text-[#61DBFB] shadow-md'>
                <SiReact />
              </span>
              <div className='text-left ml-2.5'>
                <p className='text-xs text-[#44566C] dark:text-[#A6A6A6]'>Main Stack</p>
                <p className='dark:text-white'>NestJS / ReactJS / Typescript</p>
              </div>
            </div>

            <div className='flex border-b border-[#E3E3E3] dark:border-[#3D3A3A] py-2.5'>
              <span className='socialbtn bg-white dark:bg-black text-[#6AB5B9] shadow-md'>
                <img src={language} alt='gmail' style={{ width: '1em' }} />
              </span>
              <div className='text-left ml-2.5'>
                <p className='text-xs text-[#44566C] dark:text-[#A6A6A6]'>Languages</p>
                <p className='dark:text-white'>English (C1) / Spanish (Native)</p>
              </div>
            </div>
          </div>

          <button className='items-center mx-auto bg-gradient-to-r from-[#FA5252] to-[#DD2476] duration-200 transition ease-linear hover:bg-gradient-to-l px-8 py-3 text-lg text-white rounded-[35px] mt-6'>
            <a className='flex' href={updatedCV} target='_blank' rel='noopener noreferrer'>
              <img src={downloadIcon} alt='icon' className='mr-2' />
              Download CV
            </a>
          </button>
        </div>
      </div>
    </div>
  );
};

export default HomeCard;
