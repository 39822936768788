import React from 'react';
import Sound from 'react-sound';
import jarvis from '../assets/jarvis-highway-to-hell.mp4';
import jarvisShort from '../assets/jarvis.mp3';
import highwayToHell from '../assets/highway-to-hell.mp4';

import { FaPauseCircle, FaPlayCircle } from 'react-icons/fa';

const PlaySong = ({ showLoad }) => {
  const [playing, setPlaying] = React.useState(true);
  const [finished, setFinished] = React.useState(false);

  const playSong = () => setPlaying(!playing);

  const onFinishedPlaying = () => {
    setPlaying(false);
    setFinished(true);
  };

  return (
    <React.Fragment>
      <Sound
        url={finished ? highwayToHell : jarvis}
        playStatus={playing ? Sound.status.PLAYING : Sound.status.PAUSED}
        autoLoad={true}
        loop={false}
        onFinishedPlaying={onFinishedPlaying}
      />

      {!showLoad && (
        <span onClick={playSong} className={`play-button ${playing ? 'playing' : 'pause'}`}>
          {playing ? <FaPauseCircle /> : <FaPlayCircle />}
        </span>
      )}
    </React.Fragment>
  );
};

export default PlaySong;
