import React from 'react';
import UseData from '../../Hooks/UseData';
import Footer from '../../Share/Footer';
import PageTitle from '../../Share/PageTitle';
import ResumeCard from './ResumeCard';
import { updatedCV } from '../../utils';

const ResumeTwo = () => {
  const { resumeArray } = UseData();
  return (
    <>
      <PageTitle title='Resume'></PageTitle>

      <section className='bg-white  lg:rounded-2xl dark:bg-[#111111]'>
        <div data-aos='fade'>
          <div className='container sm:px-5 md:px-10 lg:px-14'>
            <div className='py-12 px-4 md:px-0'>
              <h2 className='after-effect after:left-44'>Resume</h2>

              <div className='lg:grid grid-cols-12 md:gap-10 pt-4 md:pt-[30px] items-center hidden '>
                <div className='col-span-12 space-y-2.5'>
                  <div className='lg:mr-16'>
                    <p className='text-justify text-gray-lite  dark:text-color-910 leading-7'>
                      This is a brief resume of my career and education, for more detailed information about my previous jobs and projects, please <a href={updatedCV} target='_blank' rel="noopener noreferrer" className=' text-orange-400 underline '><strong>check out my CV</strong></a>.
                    </p>
                  </div>
                  <div></div>
                </div>
              </div>

              <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-x-6 gap-y-6 mt-[30px]'>
                {resumeArray.map((item, i) => (
                  <ResumeCard category={item} key={i} />
                ))}
              </div>
            </div>
          </div>

          <Footer condition={false} bg={'#FFFF'} />
        </div>
      </section>
    </>
  );
};

export default ResumeTwo;
